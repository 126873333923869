import styled, { css } from 'styled-components';

export const AlbumCover = styled.img`
  border: 2px solid #fff;
  max-width: 414px;
  width: 100%;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.23);
`;

export const TNWrapper = styled.div`
  width: 100%;
  max-width: 692px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  @media (max-width: 700px) {
    box-sizing: border-box;
    padding: 0 30px;
    flex-direction: column;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    display: none;
  }

  ${({ top }) =>
    top &&
    css`
      display: none;
      @media (max-width: 700px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 15px;
      }
    `};
`;

export const AlbumLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #fff;
  width: 230px;
  text-transform: uppercase;
  margin: 15px 0;
  padding: 15px 0;
  font-family: 'BDRmono';
  font-weight: normal;
  font-size: .8em;
  color: #000;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.23);
  transition: all 190ms ease-out;
  text-decoration: none;

  &:hover {
    color: #eb7f2b;
  }

  ${({ top }) =>
    top &&
    css`
      display: none;
      @media (max-width: 700px) {
        width: calc(50% - 25px);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 6px 0;
        color: #000 !important;
        padding: 10px;

        &:hover {
          color: #fff !important;
          background-color: #000;
        }
      }
      @media (max-width: 550px) {
        font-size: .8em;
      }
    `};
`;

export const Icon = styled.div`
  display: flex;
  width: 34px;
  height: 34px;
  align-items: center;
  margin-right: 7px;
`;
